import React from 'react'
import Base from './base'
import Header from './header'
import Footer from './footer'
import PageTitle from './page-title'
import LanguageSelector from './language-selector'
import ChangeList, { ChangeListData } from './changelist'
import styled from 'styled-components'
import { LanguageLink } from './language-selector'
import { Helmet } from 'react-helmet'

const Main = styled.main`
  margin: 20px 40px;

  @media screen and (max-width: 400px) {
    margin: 20px 10px;
  }
`

export const Section = styled.div`
  margin-bottom: 40px;
  text-align: justify;

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  div {
    margin-left: 20px;
    margin-bottom: 10px;
  }
`

const languageLinks: LanguageLink[] = [
  { name: 'Bahasa Indonesia', link: '/privacy-policy/id' },
  { name: 'English', link: '/privacy-policy/en' },
]

const titles = {
  en: 'Privacy Policy',
  id: 'Kebijakan Privasi',
}

const BasePrivacyPolicyPage = ({
  language,
  updateData,
  children,
}: {
  language: 'en' | 'id'
  updateData: ChangeListData
  children: React.ReactNode
}): JSX.Element => {
  const title = titles[language] ?? titles['en']
  return (
    <Base>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Header />
      <Main>
        <PageTitle>{title}</PageTitle>
        <LanguageSelector language={language} links={languageLinks} />
        <ChangeList language={language} data={updateData} />
        {children}
      </Main>
      <Footer />
    </Base>
  )
}

export default BasePrivacyPolicyPage
