import React from 'react'
import { Helmet } from 'react-helmet'
import Base, { Section } from '../../components/privacy-policy-base'

const updateData = {
  date: '09 Oktober 2021',
  changes: [
    {
      section: 'Pasal 8. Permintaan Penghapusan Data.',
      description:
        'Tambahan pasal yang menjelaskan proses permintaaan penghapusan data',
    },
  ],
}

const PrivacyPolicyPage = (): JSX.Element => (
  <Base language="id" updateData={updateData}>
    <Helmet htmlAttributes={{ lang: 'id' }}>
      <meta name="description" content="Kebijakan privasi HOKI Shop Taiwan" />
    </Helmet>
    <Section>
      <h2>1. Pengantar</h2>
      <div>
        Di HOKI Shop Taiwan ("HOKI Shop" atau "kami"), kami ingin memberikan
        pelayanan yang terbaik untuk Anda. Untuk melakukannya, kami harus
        mengumpulkan beberapa data baik data pribadi maupun tidak yang relevan
        dengan layanan yang kami berikan. Meski begitu, privasi dan keamanan
        data pribadi Anda akan selalu menjadi hal yang sangat penting bagi kami.
        Untuk itu, melalui Kebijakan Privasi ini ("Kebijakan"), kami akan
        menjelaskan secara terperinci cara dan alasan kami mengumpulkan,
        menyimpan, berbagi, dan menggunakan data pribadi yang Anda percayakan
        kepada kami.
      </div>
    </Section>
    <Section>
      <h2>2. Tentang Kebijakan Ini</h2>
      <div>
        Kebijakan ini menjelaskan detail terkait pengumpulan, penyimpanan,
        pembagian, dan penggunaan data pribadi yang kami peroleh dari Anda.
        Kebijakan ini berlaku untuk seluruh layanan yang disediakan oleh HOKI
        Shop.
      </div>
      <div>
        Apabila ada layanan kami yang menyediakan kebijakan privasi terpisah,
        maka kebijakan privasi tersebut akan diprioritaskan, sedangkan bagian
        yang tidak tercakup akan mengikuti ketentuan Kebijakan ini.
      </div>
      <div>
        Apabila Anda memiliki pertanyaan, keluhan, atau saran, Anda dapat
        melihat pasal 'Cara Menghubungi Kami' di bawah ini. Atau, apabila Anda
        tidak setuju dengan isi Kebijakan ini, harap diingat bahwa Anda dapat
        memilih apakah ingin menggunakan layanan HOKI Shop.
      </div>
    </Section>
    <Section>
      <h2>3. Informasi pribadi apa saja yang kami kumpulkan?</h2>
      <div>
        Agar dapat memberikan layanan kepada Anda, kami akan meminta Anda untuk
        memberikan informasi pribadi yang diperlukan untuk memberikan pelayanan
        tersebut. Anda berhak untuk memilih tidak memberikan informasi yang kami
        perlukan, namun itu berarti dalam kebanyakan kasus, kami mungkin tidak
        dapat memberikan pelayanan yang memerlukan data tersebut kepada Anda.
      </div>
      <div>
        Berdasarkan layanan yang Anda gunakan, kami dapat mengumpulkan informasi
        berikut:
      </div>
      <div>
        <h3>3.1 Informasi yang Anda berikan kepada kami</h3>
        <div>
          Kami dapat mengumpulkan informasi pribadi apapun yang Anda berikan
          kepada kami yang diperlukan untuk memberikan layanan yang Anda pilih.
          Misalnya, untuk menggunakan layanan kami, Anda memberikan nama, nomor
          telepon, alamat, nomor kartu identitas, alamat email, dan ID dari akun
          media sosial anda.
        </div>
        <h3>3.2 Informasi yang kami kumpulkan dari penggunaan layanan</h3>
        <div>
          Kami juga mengumpulkan informasi Anda dari penggunaan layanan. Hal ini
          termasuk, namun tidak terbatas kepada: setelan aplikasi (wilayah,
          bahasa, zona waktu), ID aplikasi, versi aplikasi, versi SDK, versi
          sistem operasi, dan status aplikasi (misalnya: menginstal,
          memperbaharui, menghapus). Kami juga mengumpulkan informasi log dan
          perilaku Anda dari server kami atau pihak ketiga, termasuk alamat IP,
          informasi log dari penggunaan layanan (seperti: waktu akses, waktu
          registrasi, waktu aktivitas), dan halaman dan fungsionalitas apa yang
          Anda akses.
        </div>
        <h3>3.3 Informasi yang bersumber dari pihak ketiga</h3>
        <div>
          Kami juga menerima informasi dari pihak ketiga mengenai Anda, termasuk
          dari pengiklan dan mitra yang bekerja sama dengan kami agar kami dapat
          memberikan pelayanan kepada Anda. Kami akan menggunakan data pribadi
          ini apabila Anda telah memberikan persetujuan kepada pihak ketiga atau
          kami untuk membagikan data tersebut atau apabila kami memiliki
          kepentingan yang sah untuk menggunakan data pribadi tersebut dalam
          memberikan pelayanan kepada Anda.
        </div>
        <h3>3.4 Informasi yang bukan merupakan informasi identitas pribadi</h3>
        <div>
          Kami juga dapat mengumpulkan informasi lain yang tidak terkait
          langsung dengan informasi pribadi atau mungkin tidak didefinisikan
          sebagai informasi pribadi menurut hukum setempat yang berlaku.
          Informasi lain termasuk data seperti ID perangkat, data pemantauan
          jaringan, kegiatan pengaksesan halaman, durasi pengaksesan halaman,
          dan lain-lain.
        </div>
      </div>
    </Section>
    <Section>
      <h2>4. Cara kami menggunakan informasi pribadi yang kami kumpulkan</h2>
      <div>
        Tujuan kami mengumpulkan informasi pribadi adalah untuk menyediakan
        layanan kepada Anda. Selain itu, informasi tersebut kami gunakan untuk
        memastikan bahwa kami mematuhi hukum dan peraturan yang berlaku. Hal ini
        meliputi:
      </div>
      <ol>
        <li>Menyediakan layanan kepada Anda</li>
        <li>Memverifikasi identitas Anda</li>
        <li>Mencegah penipuan</li>
        <li>Menerapkan dan memelihara keamanan sistem</li>
        <li>Memberikan promosi yang relevan</li>
        <li>Menyediakan layanan dan konten yang dipersonalisasikan</li>
        <li>Mendiagnosis dan memperbaiki kesalahan sistem</li>
        <li>Melakukan layanan push</li>
        <li>Melakukan aktivitas promosi</li>
        <li>Melakukan peningkatan pengalaman pengguna</li>
      </ol>
    </Section>
    <Section>
      <h2>5. Cara kami membagikan informasi pribadi ke pihak lain</h2>
      <div>
        Kami tidak menjual informasi pribadi Anda ke pihak ketiga. Kami kadang
        perlu untuk membagikan informasi pribadi Anda dengan pihak ketiga untuk
        menyediakan atau meningkatkan produk atau layanan kami.
      </div>
      <div>
        <h3>Perusahaan Grup Kami</h3>
        <div>
          Kami dapat membagikan informasi pribadi Anda dari waktu ke waktu
          kepada afiliasi HOKI Shop Taiwan.
        </div>
        <h3>Penyedia Layanan Pihak Ketiga dan Mitra Bisnis</h3>
        <div>
          Kami dapat mengunakan perusahaan atau individu pihak ketiga dalam
          memberikan layanan kepada Anda. Jika perlu, kami akan membagikan
          informasi pribadi Anda kepada penyedia layanan dan mitra bisnis kami.
          Kami menjamin bahwa pembagian informasi ini diperlukan untuk
          menyediakan layanan kepada Anda untuk tujuan yang sah, spesifik, dan
          penting.
        </div>
        <div>
          Untuk melakukan pengukuran dan analisis kinerja dan layanan kami, kami
          juga dapat memberikan informasi non-pribadi dengan pihak ketiga.
          Informasi ini digunakan untuk membantu baik kami maupun pihak ketiga
          melakukan pengukuran dan analisis tersebut.
        </div>
        <div>
          Kami tidak bertanggung jawab atas kebijakan dari organisasi lain.
        </div>
      </div>
    </Section>
    <Section>
      <h2>6. Keamanan</h2>
      <div>
        Kami menyadari pentingnya informasi pribadi Anda, oleh karena itu, kami
        akan Menerapkan berbagai prosedur untuk melindungi informasi Anda. Untuk
        mengamankan koneksi Anda kepada kami, kami menggunakan Transport Layer
        Security (TLS)/Secure Sockets Layer (SSL). Pastikan Anda mengecek bahwa
        koneksi yang Anda gunakan terenkripsi TLS/SSL agar pengiriman dan
        penerimaan data Anda aman. Semua informasi pribadi Anda disimpan di
        server dengan kontrol akses untuk mencegah akses tanpa izin.
      </div>
      <div>
        Namun perlu diingat bahwa tidak ada sistem keamanan yang sempurna.
        Apabila Anda merasa bahwa keamanan akun Anda terganggu, Anda dapat
        memberitahukan kepada kami dengan Menghubungi kami sesuai pasal 'Cara
        Menghubungi Kami'.
      </div>
    </Section>
    <Section>
      <h2>7. Periode Retensi</h2>
      <div>
        Kami akan mempertahankan informasi pribadi Anda untuk periode yang
        diperlukan untuk memenuhi tujuan yang diatur dalam Kebijakan ini dan
        sesuai dengan peraturan perundang-undangan yang berlaku.
      </div>
    </Section>
    <Section>
      <h2>8. Permintaan Penghapusan Data</h2>
      <div>
        Apabila Anda meminta kami untuk menghapus data Anda, kami juga perlu
        untuk menutup akun Anda. Data-data Anda akan dihapus atau dianonimkan
        sehingga data tersebut tidak lagi mengidentifikasi Anda. Harap diketahui
        bahwa ini berarti Anda akan kehilangan akses ke akun Anda dan semua data
        terkait dengan akun Anda (seperti riwayat pesanan) kecuali Anda telah
        menyimpannya sebelumnya.
      </div>
      <div>
        Namun, pada kasus tertentu, kami diperlukan untuk menyimpan sebagian
        dari informasi personal Anda untuk alasan hukum. Sebagai contoh:
        <ul>
          <li>
            Ada masalah yang belum terselesaikan dengan akun Anda, seperti
            tunggakan tagihan atau sengketa yang belum selesai
          </li>
          <li>
            Untuk memenuhi kewajiban hukum, perpajakan, atau akuntansi kami
          </li>
          <li>Untuk mencegah penipuan</li>
        </ul>
      </div>
      <div>
        Untuk meminta penghapusan data, Anda dapat menghubungi kami melalui
        informasi yang tertulis pada pasal 'Kontak Kami', atau dengan mengirim
        email ke app-support@xinchuan.tw.
      </div>
    </Section>
    <Section>
      <h2>9. Informasi dari Anak di Bawah Umur</h2>
      <div>
        Kami tidak menawarkan layanan secara langsung kepada anak di bawah umur.
        Selain itu, secara sadar kami tidak mengumpulkan informasi dari anak di
        bawah umur. Apabila kami mengetahui bahwa kami telah mengumpulkan
        informasi dari anak di bawah umur, kami akan menghapus informasi
        tersebut dan mengentikan layanan kepada anak tersebut.
      </div>
    </Section>
    <Section>
      <h2>10. Perubahan pada Kebijakan ini</h2>
      <div>
        Kami dapat mengubah Kebijakan ini sewaktu-waktu. Setiap perubahan dapat
        diakses pada halaman ini. Perubahan Kebijakan mulai berlaku setelah
        Kebijakan tersebut dipublikasikan pada halaman ini. Kami menganjurkan
        Anda untuk mengecek halaman ini untuk melihat apakah ada perubahan
        Kebijakan.
      </div>
      <div>
        Berikut merupakan tanggal adanya perubahan signifikan pada Kebijakan
        ini.
        <ul>
          <li>09 Oktober 2021.</li>
          <li>
            17 Februari 2021. Ada tambahan pasal mengenai perubahan signifikan
            Kebijakan dan tambahan pasal yang menjelaskan versi bahasa Kebijakan
            yang berlaku apabila ada perbedaan arti.
          </li>
          <li>22 Oktober 2019. Versi pertama dari Kebijakan ini.</li>
        </ul>
      </div>
    </Section>
    <Section>
      <h2>11. Bahasa yang Berlaku</h2>
      <div>
        Kebijakan ini mungkin diterjemahkan dan diberlakukan dalam beberapa
        bahasa selain Bahasa Indonesia namun secara keseluruhan mengacu pada
        satu Kebijakan yang sama. Apabila ada perbedaan pengartian pada
        Kebijakan terjemahan, versi Kebijakan dalam Bahasa Indonesia adalah yang
        berlaku.
      </div>
    </Section>
    <Section>
      <h2>12. Cara Menghubungi Kami</h2>
      <div>
        Jika Anda memiliki komentar, keluhan, saran, atau pertanyaan mengenai
        Kebijakan ini, Anda dapat menghubungi kami di:
      </div>
      <div>
        <h3>Alamat</h3>
        <div>
          高雄市新興區八德一路308號4樓
          <br />
          Bade 1st Road No. 308. 4th Floor.
          <br />
          Xinxing District
          <br />
          Kaohsiung City
          <br />
          Republic of China (Taiwan)
        </div>
      </div>
      <div>
        <h3>Telepon</h3>
        <div>+886 970 624 000</div>
      </div>
    </Section>
  </Base>
)

export default PrivacyPolicyPage
