import React from 'react'
import styled from 'styled-components'

export interface ChangeListData {
  date: string
  changes: {
    section: string
    description: string
  }[]
}

const Container = styled.div`
  margin: 10px 0;
  border-left: 5px solid #15a5e9;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 30px 0;
`

const Description = styled.span`
  color: #888;
  margin-left: 10px;
`

const LatestChangeDateContainer = styled.div`
  margin-bottom: 10px;
`

const ChangeList = ({
  data,
  language,
}: {
  data: ChangeListData
  language: string
}): JSX.Element => {
  let changeList = null
  if (data.changes != null && data.changes.length > 0) {
    const list = data.changes.map((change) => {
      return (
        <li key={`change-${change.section}`}>
          {change.section}
          <Description>{change.description}</Description>
        </li>
      )
    })

    const listMessage = {
      en: 'Changes are done to the following:',
      id: 'Ada perubahan pada bagian berikut:',
    }

    changeList = (
      <div>
        <div>{listMessage[language] ?? listMessage['en']}</div>
        <ul>{list}</ul>
      </div>
    )
  }

  const latest = {
    en: (date: string) => (
      <React.Fragment>
        This Privacy Policy was last updated on <b>{date}</b>.
      </React.Fragment>
    ),
    id: (date: string) => (
      <React.Fragment>
        Kebijakan Privasi ini terakhir diubah pada <b>{date}</b>.
      </React.Fragment>
    ),
  }
  const latestDisplay = (latest[language] ?? latest['en'])(data.date)

  return (
    <Container>
      <LatestChangeDateContainer>{latestDisplay}</LatestChangeDateContainer>
      {changeList}
    </Container>
  )
}

export default ChangeList
