import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export interface LanguageLink {
  name: string
  link: string
}

const Selection = styled.div`
  font-family: 'Alegreya Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #666;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  column-gap: 20px;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`

const LinkContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

const StyledLanguageLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: #666;

  &:hover {
    opacity: 0.8;
  }
`

const LanguageSelector = ({
  language,
  links,
}: {
  language: string
  links: LanguageLink[]
}): JSX.Element => {
  const gatsbyLinks = links.map((language) => (
    <StyledLanguageLink
      to={language.link}
      key={language.link}
      activeStyle={{ color: '#0076b8', fontWeight: 'bold' }}
    >
      {language.name}
    </StyledLanguageLink>
  ))

  const prefixes = {
    en: 'Available in',
    id: 'Tersedia dalam',
  }
  const prefix = prefixes[language] ?? prefixes['en']

  return (
    <Selection>
      <div>{prefix}</div>
      <LinkContainer>{gatsbyLinks}</LinkContainer>
    </Selection>
  )
}

export default LanguageSelector
